/**
 * Include all the block styles for Craft Matrix / Neo fields.
 */

.panel {
	&.-heading {
		padding-bottom: 0;
	}

	&.-no-overflow {
		overflow: hidden;
	}

	&__wysiwyg,
	&__imageTextColumn,
	&__summary {
		a:not(.button) {
			border-bottom: solid 1px $brand-dark;
			color: $brand-dark;

			&:focus,
			&:hover {
				border-bottom-color: transparent;
			}
		}
	}

	&__spacer {
		&.-small {
			height: 5vh;
		}

		&.-large {
			height: 15vh;
		}
	}
}

.block {
	&__summary {
		text-align: center;
		margin-top: 0;
	}

	&.-center {
		text-align: center;
	}
}

.-bg-white-2 {
	background-color: $white;
}

.-bg-white {
	background-color: $brand-light;
}
.-bg-dark {
	background-color: $brand-dark;
	* {
		color: $brand-light;
	}
}
.-bg-yellow {
	background-image: url('../../images/pattern-yellow.png');
}
.-bg-orange {
	background-image: url('../../images/pattern-orange.png');
}
.-bg-red {
	background-image: url('../../images/pattern-red.png');
}
.-bg-pink {
	background-image: url('../../images/pattern-pink.png');
}
.-bg-blue {
	background-image: url('../../images/pattern-blue.png');
}
.-bg-green {
	background-image: url('../../images/pattern-green.png');
}

@import 'blocks/banner';
@import 'blocks/brands';
@import 'blocks/cards';
@import 'blocks/events';
@import 'blocks/gallery';
@import 'blocks/video';
@import 'blocks/hero-banner';
@import 'blocks/image-text-column';
@import 'blocks/image';
@import 'blocks/instagramFeed';
@import 'blocks/news';
@import 'blocks/wysiwyg';
@import 'blocks/slideshow';
