.footer {
	position: relative;
	z-index: 3;
	padding-bottom: 5.5rem;
	padding-top: 0;
}

.footerTop {
	padding: 3rem 0;
	position: relative;

	p {
		margin-bottom: 0.5rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__content {
		@include media-max($medium) {
			order: -1;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex;
		}
		color: $brand-dark;
		display: flex;
		justify-content: flex-end;
	}
	&__image {
		display: block;
		width: 177px;
		@include media-max($menubreak) {
			max-width: 140px;
		}
	}
	&__phone {
		font-family: $heading-font-family;
		font-weight: bold;
		color: $brand-dark;
		font-size: 26px;
		margin-right: 3rem;
		border-bottom-width: 0;
		@include media-max($medium) {
			white-space: nowrap;
			margin-bottom: 1rem;
			width: fit-content;
			margin-right: 0;
			margin-left: 10px;
			order: 2;
		}
	}
	&__email {
		@include button();
		@include media-max($medium) {
			margin-bottom: 1rem;
			width: fit-content;
		}
		&::before {
			left: 3px;
			right: 3px;
			transform: rotate(-4deg);
		}
	}

	&__animal {
		bottom: -72px;
		left: 33%;
		animation-delay: 4s;
		&:hover {
			bottom: -46px;
			& + .-text {
				bottom: -46px;
			}
		}
		@include media-max($medium) {
			right: 8px;
			left: unset;
		}
	}
}

.footerBottom {
	@extend %fs-small;
	padding: 1rem 0;
	position: relative;
	z-index: 8;
	background-color: $brand-dark;
}

.footerNav {
	display: inline-block;

	@include media($medium) {
		margin-right: 1rem;
	}

	&__list {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	&__item {
		display: inline-block;
		padding: 0;

		&:not(:first-child)::before {
			content: '|';
			color: $brand-light;
		}
	}

	&__link {
		color: $brand-light;
		padding: 0.3rem;
		transition: all 250ms ease-in-out;
		&:focus {
			color: $brand-light;
			border: 2px dotted $brand-light;
		}
	}
	&__copyright {
		display: inline-block;
		color: $brand-light;
	}
}

.footerSupplementaryContent {
	margin: 2rem 0;
	color: $brand-dark;
	@extend %fs-xx-small;
	line-height: 1.6;

	p:last-child {
		margin-bottom: 0;
	}
}

.plastykLogo {
	margin: 2rem 0;
	font-family: Arial, sans-serif;

	.footerSupplementaryContent + & {
		@include media-max($small) {
			margin-top: 0;
		}
	}

	&__text {
		letter-spacing: -0.5px;
	}

	&__link {
		@include link($brand-dark);
		@include flex-layout(flex-start, flex-end);
		font-size: 11px;
		line-height: 13px;

		@include media($small) {
			float: right;
		}
		&:hover {
			color: $black;
		}
		width: fit-content;
	}

	&__img {
		margin: 0 3px;
		max-width: 42px;
	}
}
