/*  ==========================================================================
	# SLIDESHOW
	======================================================================= */

.slideshow {
	margin-bottom: 3rem;

	&.slick-dotted.slick-slider {
		margin-bottom: 0;
	}

	.slick-slide > div {
		display: block;
	}

	.slide {
		display: none;
		position: relative;

		@include media-max($medium) {
			.lazy--img {
				height: auto !important;
				padding-bottom: 50% !important;
			}

			.lazy--img img {
				width: auto !important;
				max-width: none !important;
				transform: translateX(-40%);
			}
		}
		@include media-max($small) {
			.lazy--img {
				padding-bottom: 100% !important;
			}
		}

		&.-trail {
			min-height: 500px;
		}

		&:first-child {
			display: block;
		}

		&__content {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;

			.pageInner {
				position: absolute;
				top: 50%;
				right: 0;
				left: 0;
				transform: translateY(-50%);
			}

			&::before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background: $black;
				opacity: 0.1;
			}
		}

		&__title {
			@extend %fs-h1;
			margin-bottom: 1rem;
			color: $brand-dark;
			font-family: $heading-font-family;
			font-weight: 700;
		}

		&__subtitle {
			@extend %fs-h5;
		}

		&__title,
		&__subtitle {
			a {
				display: inline-block;
				position: relative;
				color: #fff;
				z-index: 1;
				text-decoration: none;

				&::before {
					content: '';
					display: block;
					position: absolute;
					top: 100%;
					left: 50%;
					width: 100%;
					height: 0;
					transform: translateX(-50%);
					transition: all 250ms $transition-timing-function;
					z-index: -1;
					margin-top: -12px;
					border-top: solid 7px $brand-light;
				}

				&:hover,
				&:focus {
					&::before {
						border-color: $brand-dark;
					}
				}
			}
		}

		&__button {
			margin-top: 1rem;
		}
	}

	&.slick-initialized .slide {
		display: block;
	}

	ul.slick-dots {
		bottom: 1rem;
		margin-top: 1rem;
		text-align: center;

		> li {
			display: inline-block;
			margin: 0 0.25rem;
			list-style: none;

			button {
				display: block;
				width: 1rem;
				height: 1rem;
				overflow: hidden;
				padding: 0;
				border: none;
				transition: background-color 250ms $transition-timing-function;
				background-color: $brand-dark;
				border-radius: 50%;
				text-indent: -999px;

				&::before {
					display: none;
				}

				&:hover,
				&:focus {
					background-color: $brand-pop;
				}

				&:focus {
					outline: none;
				}
			}

			&.slick-active button {
				background-color: $brand-dark;
			}
		}
	}
}

.slideshowControls {
	margin-bottom: 2rem;

	&__current,
	&__arrow {
		display: inline-flex;
		margin: 0 0.5rem;
		padding: 0.65rem 1rem;
		color: $white;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
		font-weight: bold;
	}

	&__current {
		width: 300px;
		background-color: $brand-light;

		@include media-max($medium) {
			width: 200px;
		}
		@include media-max($small) {
			width: auto;
			padding-left: 2rem;
			padding-right: 2rem;
		}

		&[data-rating='beginner'] {
			background-color: #1d7c14;
		}

		&[data-rating='intermediate'] {
			background-color: #0059d3;
		}

		&[data-rating='advanced'] {
			background-color: #000;
		}
	}

	&__arrow {
		justify-content: space-between;
		background-color: $grey;
		cursor: pointer;

		&::before {
			font-size: 44px;
			font-family: 'Font Awesome 5 Pro';
			font-weight: 300;
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			line-height: 1;
		}

		&.-prev::before {
			content: '\f104';
			order: -1;
		}

		&.-next::before {
			content: '\f105';
			order: 1;
		}
	}
}

.trailSlideshowQuickJump {
	order: -1;

	&__link {
		@extend %fs-h5;
		position: relative;
		padding: 1rem 1rem 1.5rem;
		text-align: center;
		color: $white;
		font-weight: bold;
		cursor: pointer;

		&[data-rating='beginner'] {
			background-color: #1d7c14;
		}

		&[data-rating='intermediate'] {
			background-color: #0059d3;
		}

		&[data-rating='advanced'] {
			background-color: #000;
		}

		&::after {
			@include transition(height);
			content: '';
			position: absolute;
			top: 100%;
			right: 0;
			left: 0;
			height: 12px;
			background-color: $white;
			transform: translateY(-12px);
		}

		&.-selected::after {
			height: 6px;
		}
	}
}
