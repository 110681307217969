html {
	height: 100%;
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	@extend %fs-body;
	position: relative;
	min-height: 100%;
	background-color: $white;
}
