/*  ==========================================================================
	# HERO BANNER
	======================================================================= */

.panel__heroBanner {
	position: relative;
	overflow: hidden;
	padding: 0;
	z-index: 5;
}

.block__heroBanner {
	position: relative;
	height: 100%;
	padding: 0;
}

.heroBanner {
	position: relative;

	&__slide {
		display: none;

		&:first-child {
			display: block;
		}
	}

	&__container {
		.lazy--background {
			min-height: 400px;
		}
		&.-hasDarkOverlay {
			&::before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-color: rgba($color: $black, $alpha: 0.6);
				z-index: 1;
			}
		}
		&.-maintainAspectRatio {
			.lazy--background {
				min-height: unset;
			}
		}
	}

	&__contentWrapper {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		z-index: 2;
	}

	&__content {
		text-align: center;

		@include media-max($ipad) {
			padding: 1rem;
		}
	}

	&__title {
		@extend %fs-hero;
		@include heading(none, $pop-font-family);
		color: #fff;
		line-height: 1.1;
		max-width: 85%;
		margin: 0 auto;
	}

	&__subtitle {
		@extend %fs-h4;
		margin-top: 1rem;
		color: #fff;
		max-width: 85%;
		margin-left: auto;
		margin-right: auto;
		@include media-max($ipad) {
			margin-bottom: 0;
		}
	}

	&__button {
		z-index: 1;
		padding: 26px 100px;
		text-transform: uppercase;
		font-weight: bold;
		left: 50%;
		transform: translateX(-50%);
		width: fit-content;
		display: flex;
		margin-top: 2rem;
		justify-content: center;
		align-items: center;
		text-align: center;
		color: $brand-dark;
		transition: all 250ms ease-in-out;
		font-size: 31px;
		max-width: 600px;
		border: none;

		@include media-max($medium) {
			padding: 13px 30px;
			margin-top: 1rem;
		}
		&::before {
			background-color: $brand-yellow;
			z-index: -1;
			left: -5px;
			right: -2px;
		}
		&::after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			border: 2px solid $brand-dark;
		}

		&:hover {
			background-color: $brand-light;
			color: $brand-dark;
			border-color: $brand-dark;
			background-size: 0;
		}
	}
}

.slick-slider {
	@include media-max($ipad) {
		margin-bottom: 32px;
	}
}

.slick-dots {
	position: absolute;
	bottom: -38px;
	padding: 0 2vw;
	list-style: none;
	text-align: center;

	@include media($ipad) {
		bottom: 1rem;
	}

	li {
		display: inline-block;
		margin: 0.5rem;
		padding: 0;
	}

	button {
		display: block;
		width: 24px;
		height: 24px;
		overflow: hidden;
		border: solid 2px $white;
		border-radius: 50%;
		background-color: $white;
		text-indent: -99px;
		padding: 0;
	}

	.slick-active button {
		background-color: $brand-dark;
	}
}

.carousel__slickArrow {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 1;
	border: none;
	height: 82px;
	width: 82px;
	background-color: $brand-light;
	transition: all 250ms ease-in-out;
	span {
		transition: all 250ms ease-in-out;
		font-family: $pop-font-family;
		font-size: 43px;
		color: $brand-dark;
		font-weight: bold;
	}

	&:hover {
		background-color: $brand-dark;
		span {
			color: $brand-light;
		}
		&::after {
			border-color: $brand-light;
		}
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transition: all 250ms ease-in-out;
		background-color: transparent;
		border: 2px solid $brand-dark;
		z-index: -1;
	}

	&.slick-next {
		right: -15px;
		&::after {
			transform: rotate(-6deg);
			right: -20px;
			left: -2px;
		}
	}
	&.slick-prev {
		left: -15px;
		&::after {
			transform: rotate(6deg);
			right: -2px;
			left: -20px;
		}
	}
}

/* Video background */

.videobg {
	position: relative;
	width: 100%; /* Set video container element width here */

	// Small = 3.2:1 = 32vw / max height 600px
	// Med = 2.5:1 = 40vh / max height 768px
	// Large = 16:9 = 56.25vh / max height 1080px

	&,
	&.-small {
		height: 32vw;
		@media screen and (min-width: 1920px) {
			max-height: 600px;
		}
	}

	&.-medium {
		height: 40vw;
		@media screen and (min-width: 1920px) {
			max-height: 768px;
		}
	}

	&.-large {
		height: 56.25vw;
		@media screen and (min-width: 1920px) {
			max-height: 1080px;
		}
	}

	overflow: hidden;
	background: #111; /* bg color, if video is not high enough */

	/* horizontally center the video */
	&-width {
		position: absolute;
		width: 100%; /* Change width value to cover more area*/
		height: 100%;
		left: -9999px;
		right: -9999px;
		margin: auto;
	}

	/* set video aspect ratio and vertically center */
	&-aspect {
		position: absolute;
		width: 100%;
		height: 0;
		top: -9999px;
		bottom: -9999px;
		margin: auto;
		padding-bottom: 56.25%; /* 16:9 ratio */
		overflow: hidden;
	}

	&-make-height {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	&-container {
		width: 100%;
		height: 100%;
		overflow: hidden;
		background: #111;
		position: relative;
		z-index: -9;
	}

	iframe {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		border: 0 none;
	}
}
