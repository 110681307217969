.breadcrumbs {
	background: $brand-light;

	&.panel {
		padding: 0.5rem 0;
	}

	&__list {
		@extend %fs-x-small;
		margin: 0;
		padding: 0;
	}

	&__item {
		display: inline-block;

		span {
			color: $black;
		}

		&:not(:last-of-type)::after {
			content: '/';
			margin-right: 0.125rem;
			margin-left: 0.125rem;
			color: $black;
		}
	}

	&__link {
		background-color: transparent;
		border-bottom-color: $black;
		color: $black;

		&:hover {
			border-bottom-color: $brand-dark;
			color: $brand-dark;
		}
	}
}
