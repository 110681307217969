/*  ==========================================================================
	# Events
	======================================================================= */

.events {
	display: flex;
	flex-wrap: wrap;
}

.event {
	width: 50%;
	position: relative;
	padding: 1.5rem;
	color: $brand-dark;
	transition: color 250ms $transition-timing-function,
		background-color 250ms $transition-timing-function;

	&:nth-child(1) {
		background-color: $brand-blue;
	}
	&:nth-child(2) {
		background-color: $brand-yellow;
	}
	&:nth-child(3) {
		background-color: $brand-pink;
	}
	&:nth-child(4) {
		background-color: $brand-red;
	}
	&:nth-child(5) {
		background-color: $brand-orange;
	}

	&:hover {
		background-color: $white;
		color: $black;
	}

	&__date {
		@extend %fs-small;
		text-transform: uppercase;
		letter-spacing: 2px;
		font-weight: bold;
	}

	&__title {
		@extend %fs-h5;
		@include heading();
		color: inherit;
	}

	&__icon {
		position: absolute;
		top: 50%;
		right: 100%;
		font-size: 24px;
		opacity: 0;
		transform: translateY(-50%);
		transition: right 250ms $transition-timing-function,
			opacity 250ms $transition-timing-function;
	}

	@at-root a#{&} {
		&:hover,
		&:focus {
			.event__icon {
				right: 1.5rem;
			}
		}
	}
}
