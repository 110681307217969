.imageTextColumn {
	&__text {
		position: relative;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: flex-start;
		background-color: $brand-pink;
		padding: 1rem 1rem 2rem;
		z-index: 1;

		@include media($medium) {
			padding: 2.5rem 2rem 3.5rem;
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
		@include media($ipad) {
			padding: 3rem;
		}

		@include media-max($medium) {
			top: -4vw;

			h2,
			h3 {
				margin-top: 1rem;
			}
		}

		&.-imageleft {
			@include media($medium) {
				left: -4vw;
			}
			@include media-max($medium) {
				padding-top: 2rem;
			}
		}
		&.-imageright {
			@include media($medium) {
				right: -4vw;
			}
			@include media-max($medium) {
				padding-top: 2rem;
			}
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p,
		a:not(.button) {
			color: $brand-dark;
		}
		a:not(.button) {
			&::before {
				display: none;
			}
		}
	}

	&__image {
		position: relative;
		align-self: stretch;
		z-index: 0;
		&::before {
			content: '';
			position: absolute;
			// top: -8px;
			// right: -8px;
			// bottom: 8px;
			// left: 8px;
			padding-bottom: 100%;
			border: 3px solid $brand-dark;
			border-radius: 1000px;
			z-index: 1;
			width: 100%;
			transform: translate(2%, -2%);
			box-shadow: 0 0 0 3px $brand-light;
		}
		> .lazy--background {
			border-radius: 1000px;
			height: 0;
			overflow: hidden;
		}
		@include media-max($medium) {
			order: -1 !important;
		}
	}

	&__title {
		@extend %fs-h3;
	}

	&__button {
		@include button;
	}
}
