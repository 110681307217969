.exhibitor {
	&__topBar {
		background-color: $brand-dark;
		padding: 1.5rem 2rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		z-index: -2;
		@include media-max($medium) {
			margin-bottom: 1rem;
		}
	}
	&__heading {
		color: $brand-light;
		font-size: 31px;
		line-height: 33px;
		margin-top: 0;
		margin-bottom: 1.5rem;
	}
	&__form {
		display: flex;
		justify-content: center;
		align-items: center;
		@include media-max($medium) {
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			margin-bottom: 2rem;
		}
	}
	&__filterContainer {
		z-index: 1;
		display: block;
		width: 100%;
		justify-content: center;
	}
	&__filterLabel {
		color: $brand-light;
		font-size: 16px;
		line-height: 24px;
		font-weight: bold;
		margin: 0 0.5rem;
		&[for='minorFilter'] {
			@include media-max($medium) {
				display: none;
			}
		}
	}
	&__filterDropdown {
		height: 46px;
		border-radius: 4px;
		width: 30%;
		padding: 0 0.5rem;
		border: 0;
		color: $black;
		@include media-max($medium) {
			width: 100%;
			margin-bottom: 0.5rem;
		}
	}
	&__filterButton {
		border: 2px solid $brand-light;
		color: $brand-dark;
		top: -1px;
		padding: 12px;
		margin-left: 0.5rem;
		@include media-max($medium) {
			margin-left: 0;
			margin-top: 1rem;
		}
		&:hover {
			color: $brand-light;
		}
	}
}
