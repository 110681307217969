/*  ==========================================================================
	#Global
	======================================================================= */

html {
	min-width: 320px;
	height: 100%;
	box-sizing: border-box;
	scroll-behavior: smooth;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	@extend %fs-body;
	position: relative;
	min-height: 100%;
	background-color: $brand-light;
	overflow-x: hidden;

	margin: 0 auto;
}

.main {
	display: flex;
	flex-direction: column;
	position: relative;
	background: $brand-light;
	z-index: 100;
	overflow: hidden;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
}

.panel {
	z-index: 3;
	padding: 4rem 0;
	@include media-max($small) {
		padding: 2rem 0;
	}
}

.container {
	position: relative;
	width: 100%;
}

.link {
	@extend a;

	&.-white {
		@include link($white);
	}

	&.-black {
		@include link($black);
	}

	&.-featured {
		@include link($brand-pop);
	}

	&.-underline {
		text-decoration: underline;
	}

	&.-has-border {
		&:hover,
		&:focus {
			padding: 0.5rem;
			border: 1px solid $white;
			border-radius: $button-border-radius;
		}
	}
}

/* =============================================================================
	# Headings
================================================================================ */

.heading {
	@include heading();
	margin: $base-spacing 0 ($base-spacing / 2) 0;
	color: $brand-dark;

	&:first-child {
		margin-top: 0;
	}

	&.-superLarge {
		@extend %fs-hero;
	}

	&.-large,
	&.-pageHeading {
		@extend %fs-h1;
		margin-top: 0;
		margin-bottom: 1rem;
		text-transform: uppercase;
	}

	&.-isCentered {
		text-align: center;
	}
}

.-bold {
	font-weight: 700;
}

/* =============================================================================
	# BG
================================================================================ */

.bg {
	background-color: $white;

	&.-primary {
		background-color: $brand-light;
	}

	&.-secondary {
		background-color: $brand-dark;
	}

	&.-grey {
		background-color: $grey;
	}

	&.-dark-grey {
		background-color: $dark-grey;
	}

	&.-image {
		@include background-image();
	}
}

/*  ==========================================================================
	#Etc
	======================================================================= */

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.hidden {
	display: none;
}

.animalIcon {
	position: absolute;
	width: 160px;
	z-index: 7;
	pointer-events: all;
	transition: all 250ms ease-in-out;
	animation: shake 12s;
	animation-iteration-count: infinite;
	+ .animalIcon.-text {
		opacity: 0;
		pointer-events: none;
		z-index: 7;
		transition: all 250ms ease-in-out;
	}
	&:hover {
		+ .animalIcon.-text {
			opacity: 1;
			transition: all 0 ease-in-out;
		}
	}
	&.-small {
		width: 110px;
	}
}

.animals-screen {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	z-index: 1150;
	pointer-events: none;
}

.animal {
	pointer-events: all;
	position: fixed;
	height: 250px;
	width: auto;
	transition: all 250ms ease-in-out;
	/* Start the shake animation and make the animation last for X seconds */
	animation: shake 12s;

	/* When the animation is finished, start again */
	animation-iteration-count: infinite;
}

.animal.fish {
	bottom: -29px;
	left: -33px;
	z-index: -3;
	height: 160px;
	animation-delay: 3s;
	position: absolute;
}

.animal.fish.-text {
	opacity: 0;
	pointer-events: none;
	bottom: -29px;
	left: -33px;
}
.animal.dog:hover + .animal.dog.-text {
	transition: all 0 ease-in-out;
	bottom: -60px;
	opacity: 1;
}

.animal.reptile {
	top: -30px;
	right: -24px;
	z-index: -3;
	height: 140px;
	animation-delay: 5s;
	position: absolute;
}
.animal.reptile.-text {
	opacity: 0;
	pointer-events: none;
	top: -30px;
	right: -24px;
}

.animal.dog.-text,
.animal.dog {
	bottom: -90px;
	left: 0;
	animation-delay: 0;
}
.animal.dog.-text {
	opacity: 0;
	pointer-events: none;
	bottom: -90px;
}

.animal.dog:hover {
	bottom: -60px;
}
.animal.dog:hover + .animal.dog.-text {
	transition: all 0 ease-in-out;
	bottom: -60px;
	opacity: 1;
}

.animal.cat.-text,
.animal.cat {
	top: 100px;
	left: -110px;
	animation-delay: -6s;
}

.animal.cat.-text {
	opacity: 0;
	pointer-events: none;
}

.animal.cat:hover {
	top: 80px;
	left: -80px;
}
.animal.cat:hover + .animal.cat.-text {
	transition: all 0 ease-in-out;
	top: 80px;
	left: -80px;
	opacity: 1;
}

.animal.bird.-text,
.animal.bird {
	bottom: -120px;
	right: 0;
	animation-delay: -2s;
}
.animal.bird.-text {
	opacity: 0;
	pointer-events: none;
}

.animal.bird:hover {
	bottom: -70px;
}
.animal.bird:hover + .animal.bird.-text {
	transition: all 0 ease-in-out;
	bottom: -70px;
	opacity: 1;
}

@media screen and (max-width: 767px) {
	.animal {
		height: 150px;
	}
	.animal.cat.-text,
	.animal.cat {
		top: 100px;
		left: -70px;
	}
	.animal.cat:hover {
		top: 90px;
		left: -50px;
	}
	.animal.dog.-text,
	.animal.dog {
		bottom: 0px;
		left: -20px;
	}
	.animal.dog:hover {
		bottom: 30px;
	}

	.animal.bird.-text,
	.animal.bird {
		bottom: 0px;
		right: -20px;
	}
	.animal.bird:hover {
		bottom: 30px;
	}

	.animal.dog:hover + .animal.dog.-text {
		bottom: 30px;
	}

	.animal.cat:hover + .animal.cat.-text {
		top: 90px;
		left: -50px;
	}

	.animal.bird:hover + .animal.bird.-text {
		bottom: 30px;
	}
}
