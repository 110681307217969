@import 'photoswipe/dist/photoswipe';
$pswp__assets-path: '../images/';
@import 'photoswipe/src/css/default-skin/default-skin';

/*  ==========================================================================
	# Gallery
	======================================================================= */

.gallery {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 -0.5rem;
	width: 100%;
	&.-non-slider {
		.slick-list {
			.slick-track {
				width: 100% !important;
				display: flex;
				justify-content: center;
			}
		}
		.slick-dots {
			display: none;
		}
	}

	&__heading {
		margin-bottom: 2rem;
	}
	div.gallery__item {
		margin: 0 1rem 1rem;
	}
	&__item {
		display: flex;
		align-items: center;
		position: relative;
		width: 20%;
		overflow: visible;
		line-height: 1;
		padding: 0.5rem;
		background: transparent;
		margin: 0 0.5rem 0.5rem;
		&.-removeWonky {
				&.-large {
					&:nth-child(n) {
						img {
							transform: rotate(0deg);
						}
					}
				}
		}
		&.-small {
			@include media($large) {
				min-width: 160px;
			}
			div.image-holder,
			a {
				padding-bottom: 70%;
			}
			img {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				height: 100%;
				width: 100%;
				object-fit: contain;
				z-index: -1;
			}
			a {
				&:hover {
					border-color: $brand-dark;
				}
			}
		}

		&.-large {
			@include media($large) {
				min-width: 450px;
			}
			justify-content: center;
			div.image-holder,
			a {
				padding-bottom: 100%;
				img {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					height: 100%;
					width: 100%;
					object-fit: cover;
					z-index: -1;
				}
				&:hover {
					border-color: $brand-dark;
				}
			}
			&:nth-child(1n) {
				img {
					transform: rotate(2deg);
				}
			}
			&:nth-child(2n) {
				img {
					transform: rotate(-2deg);
				}
			}
		}

		> a {
			@include transition(all);
			position: relative;

			&::after {
				@include transition(opacity, top);
				opacity: 0;
				content: '\f00e';
				display: inline-block;
				position: absolute;
				top: 100%;
				left: 50%;
				width: 44px;
				height: 44px;
				transform: translate(-50%, -50%);
				color: $white;
				font-size: 44px;
				font-family: 'Font Awesome 5 Pro';
				font-weight: 300;
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
			}
		}

		&:hover,
		&:focus {
			> a::after {
				opacity: 1;
				top: 50%;
			}
		}

		a {
			display: block;
			margin: 0 auto !important;

			background: none !important;
			border: 3px solid transparent;
		}
	}
}

.js-GallerySlideshow-small .slick-dots,
.js-GallerySlideshow-large .slick-dots {
	bottom: 0;
	top: 100%;
	button {
		background-color: #e9e9e9;
		border-color: $brand-dark;
	}
	li.slick-active {
		button {
			border-color: $white;
		}
	}
}
