/*  ==========================================================================
	# BRANDS
	======================================================================= */

.block__brandsListing {
	margin-bottom: 2rem;
}

.brandCard {
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
	margin-right: 1rem;
	padding-bottom: 1rem;
	border: unset;

	&__image {
		margin-bottom: 0;

		img {
			display: block;
		}
	}
	&__links {
		margin: auto 0 0;
	}

	&__contentContainer {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		padding: 2rem 1.5rem 1rem 1.5rem;
		color: $brand-dark;
		background-image: linear-gradient(
			to right,
			rgba(0, 0, 0, 0.5),
			rgba(0, 0, 0, 0.5)
		);
		background-position: 50% 100%;
		background-size: 0% 6px;
		background-repeat: no-repeat;
		transition: background-size 250ms $transition-timing-function;
	}
	&__holder div:nth-child(5n + 1) &__contentContainer {
		background-color: $brand-yellow;
	}
	&__holder div:nth-child(5n + 2) &__contentContainer {
		background-color: $brand-orange;
	}
	&__holder div:nth-child(5n + 3) &__contentContainer {
		background-color: $brand-pink;
	}
	&__holder div:nth-child(5n + 4) &__contentContainer {
		background-color: $brand-red;
	}
	&__holder div:nth-child(5n) &__contentContainer,
	&__holder div:nth-child(5n + 5) &__contentContainer {
		background-color: $brand-blue;
	}

	&__title {
		@extend %fs-summary;
		margin: 0 0 1rem 0;
		padding-bottom: 0.25rem;
		line-height: 1.2;

		a {
			color: inherit;
			font-family: inherit;
		}
	}

	&__info {
		@extend %fs-small;
		margin-bottom: 1rem;
		line-height: 1.4;
	}

	.socialIcons {
		margin: 0;
	}
	.socialIcons__link {
		width: auto;
		margin-right: 1rem;
		color: $brand-dark;
		&::before {
			display: none;
		}

		&:hover,
		&:focus {
			color: $black;
		}
	}
}
