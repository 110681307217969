/*  ==========================================================================
	# Cards
	======================================================================= */

.panel__expoDealCards {
	padding: 4rem 0;
	&.-bg-dark {
		.card__blockHeading .heading {
			color: $brand-light;
		}
		.card {
			&:hover {
				&::after {
					border-color: $brand-light;
				}
			}
		}
	}
}

.expoDealCards {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 1rem;
	margin: 3rem 0 0;
	@include media-max($medium) {
		gap: 8px;
		margin: 8px 0 0;
	}
}

.expoDealCard {
	@include transition(all);
	$b: &;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;
	height: 100%;
	line-height: 1.4;
	background-color: $brand-blue;
	border: 3px solid $brand-dark;

	@include media-max($medium) {
		margin: 0;
	}

	&:not(a) {
		pointer-events: none;
	}

	&__blockHeading {
		text-align: left;
		margin-bottom: 2rem;
	}

	&__container {
		&:not(.-size-0):hover {
			.expoDealCard__imageInner {
				transform: scale(1.05);
			}
		}
		&.-big-headline {
			.expoDealCard__contentContainer {
				position: absolute;
				background: transparent;
				justify-content: flex-end;
				padding: 1.5rem;
				#{$b}__image {
					padding-bottom: 55%;
				}
				&::before {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					top: 0;
					z-index: 0;
					background-image: linear-gradient(
						to top,
						rgba(0, 0, 0, 0.68),
						rgba(255, 255, 255, 0)
					);
				}
			}
			.expoDealCard__title {
				@extend %fs-h3;
				text-transform: uppercase;
				padding-bottom: 0;
				line-height: 1.1;
				z-index: 1;
				height: auto;
			}
			.expoDealCard__description {
				@extend %fs-body;
				z-index: 1;
			}
		}
		&.-standard {
			.expoDealCard__innerContent {
				height: 100%;
			}
		}
		&.-size {
			&-30 {
				width: 100%;
				#{$b}__callout {
					height: 120px;
					width: 120px;
					font-size: 22px;
				}
				#{$b}__image {
					padding-bottom: 50%;
				}
				@include media-max($medium) {
					width: 100%;
					#{$b}__image {
						padding-bottom: 133.3%;
					}
				}
			}
			&-20 {
				width: calc(50% - (1rem / 2));
				#{$b}__callout {
					height: 100px;
					width: 100px;
					font-size: 20px;
				}
				#{$b}__image {
					padding-bottom: 50%;
				}
				@include media-max($medium) {
					width: 100%;
					#{$b}__image {
						padding-bottom: 66.6%;
					}
				}
			}
			&-10 {
				width: calc((100% / 3) - (2rem / 3));
				#{$b}__callout {
					height: 80px;
					width: 80px;
				}
				@include media-max($medium) {
					width: 100%;
					#{$b}__image {
						padding-bottom: 46.6%;
					}
				}
			}
			&-0 {
				.expoDealCard {
					cursor: default;
					&__innerContent::after {
						display: none;
					}
				}
				width: calc((100% / 3) - (2rem / 3));
				pointer-events: none;
				#{$b}__callout {
					height: 60px;
					width: 60px;
					font-size: 14px;
				}
				#{$b}__image {
					padding-bottom: 50%;
				}
				#{$b}__innerContent {
					padding-right: 0 !important;
				}
				@include media-max($medium) {
					width: calc(50% - (8px / 2));
					#{$b}__image {
						padding-bottom: 105%;
					}
				}
			}
		}
		&.-hidden {
			display: none;
		}
	}

	&__container:nth-child(6n + 1) &__image {
		// transform: rotate(-4deg);
	}
	&__container:nth-child(6n + 2) &__image {
		// transform: rotate(3deg);
	}
	&__container:nth-child(6n + 3) &__image {
		// transform: rotate(4deg);
	}
	&__container:nth-child(6n + 4) &__image {
		// transform: rotate(-3deg);
	}
	&__container:nth-child(6n + 5) &__image {
		// transform: rotate(2deg);
	}
	&__container:nth-child(6n + 6) &__image {
		// transform: rotate(-2deg);
	}

	&__callout {
		position: absolute;
		height: 80px;
		width: 80px;
		border-radius: 500px;
		line-height: 1;
		font-size: 18px;
		display: flex;
		text-align: center;
		align-items: center;
		justify-content: center;
		padding: 18px;
		font-weight: bold;
		text-transform: uppercase;
		color: $black;
		z-index: 3;
		background-color: $brand-green;
		&.-top-left {
			top: 18px;
			left: 10px;
			transform: rotate(-15deg);
		}
		&.-top-right {
			top: 18px;
			right: 10px;
			transform: rotate(15deg);
		}
		&.-bottom-left {
			bottom: 18px;
			left: 10px;
			transform: rotate(-15deg);
		}
		&.-bottom-right {
			bottom: 18px;
			right: 10px;
			transform: rotate(15deg);
		}
	}

	&__image {
		width: 104%;
		left: -2%;
		top: -3%;
		clip-path: polygon(0 6.5%, 98% 0, 100% 93.5%, 2% 100%);
		padding-bottom: 75%;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		background-color: $white;
		overflow: hidden;
		display: block;
		transition: all 250ms ease-in-out;
		position: relative;
		z-index: 2;
	}

	&__imageInner {
		position: absolute;
		height: 100%;
		width: 100%;
		object-fit: cover;
		transition: transform 250ms ease-in-out;
		z-index: 2;
		&.-mobile {
			display: none;
			@include media-max($medium) {
				display: block;
			}
		}
		&.-desktop {
			display: block;
			@include media-max($medium) {
				display: none;
			}
		}
		&.-placeholder {
			object-fit: contain;
		}
	}

	&__contentContainer {
		position: relative;
		height: 100%;
		width: 100%;
		padding: 0 1rem 1rem;
		transition: background-color 350ms $transition-timing-function;
		display: flex;
		flex-direction: column;
		justify-content: baseline;
		align-items: baseline;

		color: $brand-dark;
		overflow: hidden;

		&.-has-modal {
			.expoDealCard__innerContent {
				padding-right: 30px;
				&::after {
					content: '\f105';
					font-family: 'Font Awesome 5 Pro';
					font-weight: 400;
					position: absolute;
					top: 50%;
					right: 10px;
					font-size: 36px;
					color: $brand-dark;
					transform: translateY(-50%);
				}
			}
		}
	}
	&__innerContent {
		position: relative;
		width: 100%;
		height: auto;
	}
	&__title {
		@extend %fs-h5;
		line-height: 1.05;
		position: relative;
		width: 100%;
		padding-bottom: 2px;
		margin-bottom: 1rem;
		font-family: $heading-font-family;
		margin: 0;
		text-align: left;
		color: $brand-dark;
		transition: all 250ms ease-in-out;
	}
	&__description {
		@extend %fs-small;
		line-height: 1.1;
		margin: 0;
		transition: all 250ms ease-in-out;
	}

	&__content + &__link {
		flex-grow: 0;
	}
	&:hover {
		background-color: $brand-dark;
		color: $brand-light !important;
		.expoDealCard__innerContent::after,
		.expoDealCard__description,
		.expoDealCard__title {
			color: $brand-light;
		}
	}
}
