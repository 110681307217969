/**
* Visually hide an element, but leave it available for screen readers
* @link http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
*/

.screenReaderOnly {
	position: absolute;
	width: 1px;
	height: 1px;
	overflow: hidden;
	margin: -1px;
	padding: 0;
	border: 0;
	clip: rect(0 0 0 0);
}

/**
* Extends the .screenReaderOnly class to allow the element to be focusable when navigated to via the keyboard
* @link https://www.drupal.org/node/897638
*/

.screenReaderFocusable:active,
.screenReaderFocusable:focus {
	position: static;
	width: auto;
	height: auto;
	overflow: visible;
	margin: 0;
	clip: auto;
}
