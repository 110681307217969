/*  ==========================================================================
	# Buttons
	@include button(bg, colour, border-colour)
	Default button(#00B140, #ffffff, #00B140)
	======================================================================= */

.button,
.fui-btn-container > .fui-btn {
	@extend %fs-body;
	@include button;
	display: inline-block;

	@include media-max($small) {
		width: 100%;
		padding-left: 1rem;
		padding-right: 1rem;
		text-align: center;
	}

	&.-isBlockButton {
		margin: 2rem 0 0 0;
	}

	&.-small {
		padding: 0.75rem 1.5rem;
	}

	&.-text-small {
		@extend %fs-xx-small;
	}

	&.-plain {
		background: none;
		border: unset;

		> i {
			margin-left: 1rem;
		}
	}

	&.-is-disabled,
	&:disabled {
		@include button($mid-grey, $black, $mid-grey);
		cursor: not-allowed;

		&:hover,
		&:focus {
			background-color: $mid-grey;
			border-color: $mid-grey;
			color: $black;
		}
	}
}

.panel__button {
	&.-bg-dark {
		a.button.-isBlockButton {
			border-color: $brand-light;
			color: $brand-dark;
			&:hover {
				color: $brand-light;
			}
		}
	}
}
