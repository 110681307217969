.panel__video {
	padding: 5vw 0;

	.videoBlock {
		&__container {
			position: relative;
			padding-bottom: 56.25%;
			height: 0;
			overflow: hidden;
			max-width: 100%;

			&.-padded {
				margin: 30px 0;
			}

			iframe.videoBlock__video {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

		&__title {
			@extend %fs-h2;
			text-align: center;
			margin-top: 0.5em;
			margin-bottom: 0.3em;
		}
		&__content {
			@extend %fs-h5;
			text-align: center;
		}
		&__sizer {
			max-width: 910.24px;
		}
	}
}
