/*  ==========================================================================
	# NEWS
	======================================================================= */

.block__newsListing {
	margin-bottom: 2rem;
}

.newsCategories {
	list-style: none;
	display: flex;
	overflow-x: auto;
	overflow-y: hidden;
	margin-bottom: 2rem;
	@include media-max($medium) {
		flex-wrap: nowrap;
		margin: 0 auto 2rem;
		justify-content: left;
		width: 100%;
	}
	&__container {
		@include media-max($medium) {
			margin: 0 -4vw;
			width: calc(100% + 8vw);
		}
	}
	&__heading {
		margin-top: 0;
		color: $brand-dark;
		@extend %fs-h5;
	}

	&__item {
		&:last-child {
			border-right: 1px solid $brand-dark;
		}
	}

	&__link {
		@extend %fs-x-small;
		display: block;
		padding: 0.825rem 1rem;
		border-top: 1px solid $brand-dark;
		border-left: 1px solid $brand-dark;
		border-bottom: 1px solid $brand-dark;
		color: $black;
		white-space: nowrap;

		&:hover,
		&:focus {
			background-color: $brand-green;
		}

		&.-current {
			background-color: $brand-dark;
			color: $brand-light;
		}
	}
}

.news {
	padding: 2rem 0;

	@include media($ipad) {
		padding: 5rem 0;
	}

	&__heading {
		@extend %fs-h1-big;
	}

	&__image {
		margin-bottom: 2rem;
	}

	&__info {
		padding-bottom: 0.5rem;
		padding-top: 0.7rem;
		border-top: solid 1px #404040;
	}

	&__article {
		@include media($medium) {
			padding-right: 4rem;
		}
	}

	&__wysiwyg {
		color: $brand-dark;
		line-height: 1.25;
		a {
			@include link();
		}
		p {
			margin-bottom: 1rem;
		}
	}

	&__revealButton {
		display: flex;
		width: 75%;
		margin: 2rem auto 3rem;
		text-align: center;
		justify-content: center;
	}
}

.newsCard,
.news {
	&__image {
		margin-bottom: 1rem;
	}

	&__info {
		@extend %fs-x-small;
		display: flex;
		justify-content: space-between;
		margin-bottom: 0.5rem;
		font-size: 14px;
		font-family: $base-font-family;
		@include media-max($medium) {
			font-size: 12px;
		}
	}
}

.newsCard {
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
	margin-right: 1rem;
	margin-bottom: 1rem;
	border: unset;

	&::before {
		background-color: $brand-dark;
	}

	@include media-max($medium) {
		&.-side-by-side-responsive {
			flex-direction: row;

			.newsCard__image {
				width: 50%;
				height: 100%;
				.newsCard__placeholderContainer {
					height: 100%;
				}
			}
			.newsCard__info {
				order: 2;
				margin-bottom: 0;
			}
			.newsCard__date {
				display: none;
			}
			.newsCard__contentContainer {
				width: 50%;
			}
		}
	}

	&__info {
		font-size: 12px;
		margin-bottom: 1rem;
	}

	&__image {
		margin-bottom: 0;

		img {
			display: block;
		}
	}

	&__placeholder {
		background: $brand-light;
		object-fit: contain;
		padding: 4%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		height: 100%;
		&Container {
			padding-bottom: 66.6667%;
			height: 0;
			position: relative;
		}
	}

	&__column {
		margin-bottom: 33px;

		@include media-max($medium) {
			margin-bottom: 16px;
			&.-flip-every-second-responsive {
				&:nth-child(2n) {
					.newsCard__image {
						order: 2;
					}
				}
			}
		}
	}

	&__contentContainer {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		padding: 0.5rem 1rem 1rem;
	}
	&__column:nth-child(5n + 1) &__contentContainer {
		background-color: $brand-green;
	}
	&__column:nth-child(5n + 2) &__contentContainer {
		background-color: $brand-blue;
	}
	&__column:nth-child(5n + 3) &__contentContainer {
		background-color: $brand-pink;
	}
	&__column:nth-child(5n + 4) &__contentContainer {
		background-color: $brand-blue;
	}
	&__column:nth-child(5n) &__contentContainer,
	&__column:nth-child(5n + 5) &__contentContainer {
		background-color: $brand-red;
	}

	&__title {
		@extend %fs-summary;
		margin: 0;
		padding-bottom: 0.25rem;
		line-height: 1.2;
		color: $brand-dark;
	}

	&__content {
		@extend %fs-small;
		max-height: 0;
		overflow: hidden;
		line-height: 1.4;

		transition: max-height 250ms $transition-timing-function;
	}

	@at-root a#{&} {
		color: $black;

		&:hover,
		&:focus {
			.newsCard__contentContainer {
				background-size: 100% 6px;
			}
			.newsCard__content {
				max-height: 300px;
			}
		}
	}
}

.recentNews {
	@include media($large) {
		padding-left: 2rem;
	}

	&__heading {
		margin-top: 0;
		color: $brand-dark;
		@extend %fs-h4;
	}

	&__list {
		list-style: none;
		margin-top: 1rem;
	}

	&__link {
		@extend %fs-small;
		position: relative;
		display: block;
		padding: 0.45rem 0 0.6rem;
		border-top: 1px solid transparent;
		padding-left: 1.3rem;
		background-color: transparent;
		color: $brand-dark;
		line-height: 1.1;
		transition: all 250ms ease-in-out;

		&.-selected {
			background-color: $brand-dark;
			color: $brand-light;
		}

		&:hover {
			background-color: $brand-pop;
			color: $brand-dark;
			&::before {
				opacity: 0;
			}
		}
		&::after {
			content: '\f105';
			display: inline-block;
			position: absolute;
			top: 15px;
			left: 0;
			transform: translateY(-50%);
			margin-right: 0.75rem;
			margin-left: 0.3rem;
			font-size: 17px;
			font-family: 'Font Awesome 5 Pro';
			font-weight: bold;
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			line-height: 1.25;
		}
	}
}

.socialShares {
	&__label {
		font-weight: bold;
		color: $brand-dark;
		font-size: 18px;
		margin-bottom: 0.75rem;
	}
	&__shareToText {
		&.-hidden {
			display: none;
		}
	}
	&__icon {
		width: 48px;
		height: 46px;
		padding: 0;
		margin-right: 20px;
		&::before {
			left: -4px;
			right: -4px;
			top: 2px;
			bottom: -2px;
			transform: rotate(-5deg);
		}
		.envelope-icon {
			height: 20px;
		}
		span,
		.envelope-icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	&__container {
		@include media-max($medium) {
			margin-bottom: 3.5rem;
		}
	}
}

.recentNewsSlider {
	&__blockHeading {
		padding-top: 40px;
	}
	&__slider {
		padding-bottom: 20px;
		@include media($medium) {
			padding-left: 45px;
			padding-right: 45px;
		}
		.slick-track {
			display: flex;
		}
		.slick-slide {
			height: inherit;
			transition: all 250ms ease-in-out;
		}

		.slick-center {
			@media screen and (max-width: 450px) {
				scale: 1.1;
				z-index: 1;
			}
		}
	}
	.newsCard {
		margin-left: 10px;
		margin-right: 10px;
		&__column {
			margin: 33px 0;
		}
	}
}
