/*  ==========================================================================
	# Instagram Feed
	======================================================================= */

.panel__instagramFeed {
	z-index: 11;
	background-color: $brand-dark;
	color: $brand-light;
	padding: 0;
}

.instagramFeed {
	&__content {
		padding: 37px 0;
		text-align: left;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		@include media-max($small) {
			padding-bottom: 0.75rem;
			flex-direction: column;
		}
	}
	&__heading {
		.heading {
			margin-bottom: 0;
			color: $brand-light;
		}
		.subheading {
			margin: 0;
			font-family: $base-font-family;
			font-weight: 400;
			font-size: 16px;
			color: #888b8d;
			@include media-max($small) {
				text-align: center;
			}
		}
	}

	&__subtitle {
		color: $white;
	}

	&__button {
		color: $white;
		border-bottom: 2px solid transparent !important;
		padding: 0;
		height: fit-content;
		font-family: $heading-font-family;
		font-weight: bold;
		i {
			margin-left: 10px;
		}
		&::before {
			display: none;
		}

		&:hover,
		&:active,
		&:focus {
			color: $brand-pop;
			border-bottom-color: $brand-pop !important;
		}
	}

	&__images {
		display: flex;
		justify-content: center;

		@include media-max($medium) {
			flex-wrap: wrap;
		}
	}

	&__link {
		border-bottom: none;

		@include media-max($medium) {
			width: 50%;
		}
	}

	&__image {
		display: block;
		width: 100%;
	}

	&__mediaIcon {
		height: 22px;
		width: 22px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: block;
		fill: $white;
		filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.4));
	}
}
