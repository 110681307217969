// pad-col - helper function for generating padding equal to a column, even in nested containers
@function pad-col($paddingColsRequired, $totalCols) {
	$percentage: ($paddingColsRequired / $totalCols) * 100%;
	@return $percentage;
}

// contain floats
@mixin clearfix() {
	*zoom: 1;

	&::before,
	&::after {
		content: '';
		display: table;
	}

	&::after {
		clear: both;
	}
}

// visually hidden
@mixin visuallyhidden() {
	position: absolute;
	width: 1px;
	height: 1px;
	overflow: hidden;
	margin: -1px;
	padding: 0;
	border: 0;
	clip: rect(0 0 0 0);
}

// Stripping out units
@function strip-unit($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return $number / ($number * 0 + 1);
	}

	@return $number;
}

@mixin link(
	$color: $brand-dark,
	$border-bottom-color: $brand-dark,
	$text-decoration: none
) {
	position: relative;
	color: $color;
	font-family: $base-font-family;
	border-bottom: solid 2px $border-bottom-color;
	text-decoration: $text-decoration;
	transition: background-color $base-duration $base-timing,
		border-bottom-color $base-duration $base-timing,
		color $base-duration $base-timing;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: -2px;
		left: 0;
		opacity: 0;
		background-color: $brand-pop;
		z-index: -1;
		transform: rotate(0deg);
		transition: all 250ms ease-in-out;
	}
	&:hover {
		&::before {
			opacity: 1;
			transform: rotate(-2.5deg);
		}
	}

	&:focus {
		outline: 2px dotted $brand-dark;
		color: $brand-dark;
	}
}

@mixin lightLink(
	$color: $brand-light,
	$border-bottom-color: $brand-light,
	$text-decoration: none
) {
	position: relative;
	color: $color;
	font-family: $base-font-family;
	border-bottom: solid 2px $border-bottom-color;
	text-decoration: $text-decoration;
	transition: background-color $base-duration $base-timing,
		border-bottom-color $base-duration $base-timing,
		color $base-duration $base-timing;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: -2px;
		left: 0;
		opacity: 0;
		background-color: $brand-pop;
		z-index: -1;
		transform: rotate(0deg);
		transition: all 250ms ease-in-out;
	}
	&:focus {
		outline: 2px dotted $brand-light;
		color: $brand-light;
	}

	&:hover {
		color: $brand-dark;
		border-bottom-color: $brand-dark;
		&::before {
			opacity: 1;
			transform: rotate(-2.5deg);
		}
	}
}

@mixin button(
	$bg-colour: $brand-pop,
	$colour: $brand-dark,
	$border-color: $brand-dark
) {
	@extend %fs-summary;
	position: relative;
	padding: 12px 35px;
	background-color: transparent;
	border: 2px solid $brand-dark;
	color: $colour;
	font-family: $heading-font-family;
	font-weight: bold;
	font-size: 18px;
	text-transform: uppercase;
	line-height: 1;
	text-decoration: none;
	letter-spacing: 2px;
	cursor: pointer;
	transition: all $base-duration $base-timing;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 1;
		background-color: $brand-pop;
		z-index: -1;
		transform: rotate(-2.5deg);
		transition: all 250ms ease-in-out;
	}
	a.button::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: $brand-light;
		z-index: -2;
	}

	&:hover {
		color: $brand-dark;
		&::before {
			opacity: 0;
			transform: rotate(10deg);
		}
	}

	&:focus {
		border-style: dotted;
		color: $brand-dark;
		outline: none;
	}
	&:active {
		color: $brand-light;
		border-style: solid;
		&::before {
			background-color: $brand-dark;
			opacity: 1;
			transform: rotate(-2.5deg);
		}
	}
}

@mixin lightButton(
	$bg-colour: $brand-dark,
	$colour: $brand-light,
	$border-color: $brand-light
) {
	@extend %fs-summary;
	position: relative;
	padding: 12px 35px;
	background-color: transparent;
	border: 2px solid $brand-dark;
	color: $colour;
	font-family: $heading-font-family;
	font-weight: bold;
	font-size: 18px;
	text-transform: uppercase;
	line-height: 1;
	text-decoration: none;
	letter-spacing: 2px;
	cursor: pointer;
	transition: all $base-duration $base-timing;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 1;
		background-color: $brand-pop;
		z-index: -1;
		transform: rotate(-2.5deg);
		transition: all 250ms ease-in-out;
	}
	a.button::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: $brand-light;
		z-index: -2;
	}

	&:hover {
		color: $brand-dark;
		&::before {
			opacity: 0;
			transform: rotate(10deg);
		}
	}

	&:focus {
		border-style: dotted;
		color: $brand-dark;
		outline: none;
	}
	&:active {
		color: $brand-light;
		border-style: solid;
		&::before {
			background-color: $brand-dark;
			opacity: 1;
			transform: rotate(-2.5deg);
		}
	}
}

@mixin heading(
	$tt: none,
	$ff: $heading-font-family,
	$fw: $heading-font-weight
) {
	font-family: $ff;
	font-weight: $fw;
	text-transform: $tt;
}

@mixin flex-layout($justify-content: flex-start, $align-items: center) {
	display: flex;
	// flex-wrap: wrap;
	justify-content: $justify-content;
	align-items: $align-items;
}

@mixin transition(
	$property,
	$duration: 250ms,
	$effect: $transition-timing-function
) {
	&:not(.no-transition) {
		transition: $property $duration $effect;
	}
}

@mixin background-image(
	$size: cover,
	$horizontal-position: center,
	$vrtical-position: center
) {
	position: relative;
	background-size: $size;
	background-position: $horizontal-position $vrtical-position;
	background-repeat: no-repeat;
}

// =============================================================================
// Responsive helpers
// =============================================================================

/**
 * Fluid Property Helper
 * Article: https://bit.ly/2MaHaXb

 * Basic font scale:
 * .component { @include fp(font-size, 12, 24); }
 *
 * Width scale with custom breakpoint min/max:
 * .component { @include fp(width, 12, 24, $small, $medium); }
 *
 * Or define a non-linear scale:
 * .component {
 *   @include fp(font-size, 10, 200, $small, $medium, $remove-end: true);
 *   @include fp(font-size, 20, 300, $medium, $large, $remove-start: true, $remove-end: true);
 *   @include fp(font-size, 30, 400, $large, $max, $remove-start: true);
 * }
 */
@mixin fp(
	$property,
	$min-value,
	$max-value,
	$start-breakpoint: $small,
	$end-breakpoint: $max,
	$source-unit: vw,
	$output-unit: px,
	$remove-start: false,
	$remove-end: false
) {
	$min-value: strip-unit($min-value);
	$max-value: strip-unit($max-value);
	$start-breakpoint: strip-unit($start-breakpoint);
	$end-breakpoint: strip-unit($end-breakpoint);

	$multiplier: ($max-value - $min-value) /
		($end-breakpoint - $start-breakpoint) * 100;
	$adder: ($min-value * $end-breakpoint - $max-value * $start-breakpoint) /
		($end-breakpoint - $start-breakpoint);

	$formula: calc(#{$multiplier + $source-unit} + #{$adder + 0+$output-unit});

	// Start
	// Capped from just before $start-breakpoint
	// Fluid from $start-breakpoint to just before $end-breakpoint
	@if ($remove-end) and not ($remove-start) {
		@media (max-width: #{$start-breakpoint - 1px}) {
			#{$property}: $min-value + 0#{$output-unit};
		}
		@media (min-width: #{$start-breakpoint + 0px}) and (max-width: #{$end-breakpoint - 1px}) {
			#{$property}: $formula;
		}

		// Middle stretch
		// Fluid from $start-breakpoint to just before $end-breakpoint
	} @else if ($remove-start) and ($remove-end) {
		@media (min-width: #{$start-breakpoint + 0px}) and (max-width: #{$end-breakpoint - 1px}) {
			#{$property}: $formula;
		}

		// End
		// Fluid from $start-breakpoint to just before $end-breakpoint
		// Capped from $end-breakpoint
	} @else if ($remove-start) and not ($remove-end) {
		@media (min-width: #{$start-breakpoint + 0px}) and (max-width: #{$end-breakpoint - 1px}) {
			#{$property}: $formula;
		}
		@media (min-width: #{$end-breakpoint + 0px}) {
			#{$property}: $max-value + 0#{$output-unit};
		}

		// Full stretch
		// Capped from just before $start-breakpoint
		// Fluid from $start-breakpoint to just before $end-breakpoint
		// Capped from $end-breakpoint
	} @else {
		@media (max-width: #{$start-breakpoint - 1px}) {
			#{$property}: $min-value + 0#{$output-unit};
		}
		@media (min-width: #{$start-breakpoint + 0px}) and (max-width: #{$end-breakpoint - 1px}) {
			#{$property}: $formula;
		}
		@media (min-width: #{$end-breakpoint + 0px}) {
			#{$property}: $max-value + 0#{$output-unit};
		}
	}
}

// breakpoints
@mixin media($point) {
	@media screen and (min-width: $point) {
		@content;
	}
}

@mixin media-max($point) {
	@media screen and (max-width: $point - 1px) {
		@content;
	}
}

@mixin media-span($from, $to) {
	@media screen and (min-width: $from) and (max-width: $to - 1px) {
		@content;
	}
}
