/*  ==========================================================================
	# Variables
	======================================================================= */

// Typography
$base-font-family: 'brother-1816', Arial, sans-serif;
$heading-font-family: 'Chaloops', Arial, sans-serif;
$pop-font-family: 'chauncy-pro', Arial, sans-serif;

// Font variations
$base-font-weight: 400;
$heading-font-weight: 800;

// Line height
$base-line-height: 1.6;
$reduced-line-height: 1.2;

// Other Sizes
$base-border-radius: 0;
$bbr: $base-border-radius;
$button-border-radius: $base-border-radius;
$base-spacing: $base-line-height * 1rem;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Repeated styles
$text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
$transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);

// Font Colors
$base-font-color: $brand-dark;
$action-color: $brand-pop;

// Animations
$base-duration: 250ms;
$base-timing: ease-in-out;

// Borders
$base-border-color: $gray;
$base-border-width: 2px;
$base-border: $base-border-width solid $base-border-color;
$box-shadow: 0 1.3px 2px rgba(0, 0, 0, 0.02), 0 3.1px 4.8px rgba(0, 0, 0, 0.028),
	0 5.8px 9px rgba(0, 0, 0, 0.035), 0 10.3px 16.1px rgba(0, 0, 0, 0.042),
	0 19.2px 30.1px rgba(0, 0, 0, 0.05), 0 46px 72px rgba(0, 0, 0, 0.07); /* https://shadows.brumm.af/ */

$base: 0;
$small: 480px;
//$nexus: 600px;
$medium: 768px;
$ipad: 1024px;
$large: 1280px;
$xlarge: 1440px;
//$huge: 1681px;
$max: 1920px;

//Size at which the menu breaks
$menubreak: 768px;
