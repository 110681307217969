/*  ==========================================================================
	# Font Sizes
	=======================================================================

	-- This is all based on a 8px grid that the designs are build on
	-- Its important to match the line-heights rather than the font size,
	-- in order to maintain spacing between text.

	----------  // font-size/line-height
	%fs-jumbo-xl // 140px/1   (140px)
	%fs-jumbo    // 112px/1   (112px)
	%fs-hero     // 80px/1.2  (96px)
	%fs-h1       // 50px/1.2  (60px)
	%fs-h2       // 40px/1.2  (48px)
	%fs-h3       // 34px/1.2  (40.8px)
	%fs-h4       // 27px/1.2  (32.4px)
	%fs-h5       // 24px/1.2  (28.8px)
	%fs-h6       // 20px/1.6  (32px)
	%fs-summary  // 20px/1.6  (32px)
	%fs-body     // 18px/1.75 (31.5px)
	%fs-small    // 16px/1.75 (28px)
	%fs-x-small  // 14px/1.45 (20.3px)
	%fs-xx-small // 12px/1.65 (19.8px)
*/

@font-face {
	font-family: 'Ad Lib';
	src: url('../../webfonts/AdLibFont.woff') format('woff'),
		/* Safari, Android, iOS */ url('../../webfonts/AdLibFont.woff2')
			format('woff2');
}

@font-face {
	font-family: 'DIN Regular';
	font-style: normal;
	font-weight: normal;
	src: url('../../webfonts/DIN-2014_Regular.woff') format('woff'),
		/* Safari, Android, iOS */ url('../../webfonts/DIN-2014_Regular.woff2')
			format('woff2');
}

%fs-jumbo-xl {
	@include fp(
		'font-size',
		80,
		100,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		100,
		120,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		120,
		140,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
	line-height: 1;
}

%fs-jumbo {
	@include fp(
		'font-size',
		60,
		80,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		80,
		96,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		96,
		112,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
	line-height: 1;
}

%fs-hero {
	@include fp(
		'font-size',
		30,
		40,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		40,
		60,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		60,
		80,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
	line-height: 1.2;
}

%fs-h1-big {
	@include fp(
		'font-size',
		34,
		37,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		37,
		41,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		41,
		45,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
	line-height: 1.2;
}

%fs-h1 {
	@include fp(
		'font-size',
		28,
		32,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		32,
		35,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		35,
		37,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
	line-height: 1.2;
}

%fs-h2 {
	@include fp(
		'font-size',
		28,
		32,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		32,
		36,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		36,
		40,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
	line-height: 1.2;
}

%fs-h3 {
	@include fp(
		'font-size',
		26,
		28,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		28,
		30,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		30,
		34,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
	line-height: 1.2;
}

%fs-h4 {
	@include fp(
		'font-size',
		22,
		24,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		24,
		26,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		26,
		27,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
	line-height: 1.2;
}

%fs-h5 {
	@include fp(
		'font-size',
		18,
		20,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		20,
		22,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		22,
		24,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
	line-height: 1.4;
}

%fs-h6,
%fs-summary {
	@include fp(
		'font-size',
		18,
		20,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		20,
		22,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		22,
		24,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
	line-height: 1.5;
}

%fs-body {
	@include fp(
		'font-size',
		16,
		16,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		16,
		17,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		17,
		18,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
	line-height: 1.75;
}

%fs-small {
	@include fp(
		'font-size',
		14,
		15,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		15,
		16,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		16,
		16,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
	line-height: 1.75;
}

%fs-x-small {
	@include fp(
		'font-size',
		12,
		13,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		13,
		13,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		13,
		14,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
	line-height: 1.45;
}

%fs-xx-small {
	@include fp(
		'font-size',
		10,
		11,
		$small,
		$medium,
		$remove-start: false,
		$remove-end: true
	);
	@include fp(
		'font-size',
		11,
		11,
		$medium,
		$ipad,
		$remove-start: true,
		$remove-end: true
	);
	@include fp(
		'font-size',
		11,
		12,
		$ipad,
		$max,
		$remove-start: true,
		$remove-end: false
	);
	line-height: 1.65;
}

/*  ==========================================================================
	# Typography
	======================================================================= */

%caps {
	font-family: $base-font-family;
	text-transform: uppercase;
	letter-spacing: 0.25em;
}

body {
	@extend %fs-body;
	margin: 0;
	color: $base-font-color;
	font-family: $base-font-family;
	font-weight: $base-font-weight;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include heading;
	//margin: ($base-spacing * 2) 0 $base-spacing 0;
	margin: 2em 0 0.5em;
}

h1 {
	@extend %fs-h1;
}

h2 {
	@extend %fs-h2;
}

h3 {
	@extend %fs-h3;
}

h4 {
	@extend %fs-h4;
}

h5 {
	@extend %fs-h5;
}

h6 {
	@extend %fs-h6;
	font-weight: bold;
}

p {
	margin: 0 0 $base-spacing;

	&.-small {
		@extend %fs-x-small;
	}

	&.-tiny {
		@extend %fs-xx-small;
	}
}

a {
	@include link();
}

a.-light {
	@include lightLink();
}

hr {
	border-bottom: $base-border;
	border-left: 0;
	border-right: 0;
	border-top: 0;
	margin: $base-spacing 0;

	&.-large {
		border-bottom-width: calc(3 * #{$base-border-width});
		border-bottom-color: $brand-pop;
	}
}

.hr-row.row {
	@include media-max($ipad) {
		flex-direction: column;
	}
}

img,
picture {
	margin: 0;
	max-width: 100%;
}

ul,
ol {
	margin: 0;
	padding: 0;
	color: $brand-dark;
	list-style-position: inside;
	&%default-ul {
		list-style-type: disc;
		margin-bottom: $small-spacing;
		padding-left: $base-spacing;
		text-align: left;
	}

	&%default-ol {
		list-style-type: decimal;
		margin-bottom: $small-spacing;
		padding-left: $base-spacing;
	}
}

dl {
	margin-bottom: $small-spacing;

	dt {
		font-weight: bold;
		margin-top: $small-spacing;
	}

	dd {
		margin: 0;
	}
}

// table {
// 	border-collapse: collapse;
// 	font-feature-settings: 'kern', 'liga', 'tnum';
// 	margin: 0;
// 	width: 100%;
// }

// th {
// 	border-bottom: 1px solid shade($base-border-color, 25%);
// 	font-weight: 600;
// 	padding: $small-spacing 0;
// 	text-align: left;
// }

// td {
// 	border-bottom: $base-border;
// 	padding: $small-spacing 0;
// }

// tr,
// td,
// th {
// 	vertical-align: middle;
// }
