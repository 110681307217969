.customForm {
	p {
		@extend %fs-h4;
	}

	.fui-i,
	.fui-label,
	.fui-instructions {
		color: $black;
	}
	.fui-required {
		opacity: 0;
	}
	.fui-input,
	.fui-select {
		border-color: #777;
		color: $black;
	}
	.fui-btn {
		background-color: $brand-pop;
		border-color: $brand-pop;
		color: $black;
		transition: all 250ms ease-in-out;
		&:active,
		&:focus,
		&:hover {
			background-color: $black;
			color: $white;
			border-color: $black;
			background-size: 0;
		}
	}
	textarea {
		min-height: 120px;
	}
	.fui-error-message {
		@extend %fs-x-small;
		margin-top: 0;
		padding: 0.5rem;
		background-color: $brand-red;
		color: $black;
		line-height: 1.2;
	}
	.fui-alert-success {
		color: $black;
	}
	.fui-btn:disabled {
		background-color: $dark-grey;
		border-color: $dark-grey;
	}
}
