.login__form-wrapper,
.forgot-password__form-wrapper {
	min-height: 80vh;
}

.login-form,
.forgot-password-form {
	max-width: 400px;
	margin: 0 auto;

	&__button {
		margin-top: 1.5rem;
		min-width: 280px;
		max-width: 300px;
	}

	&__field {
		margin: 1rem 0.5rem;
	}

	&__field--text {
		label,
		input {
			color: $black;
		}

		&.focus,
		&.has-value {
			label {
				color: $mid-grey;
			}
		}
	}

	&__field--checkboxlist,
	&__forgot-password {
		margin: 0 0.5rem 0.5rem;

		input:checked ~ label {
			color: $white;
		}

		label {
			color: $white;
		}
	}

	&__label {
		color: $white;
	}

	span.error {
		font-weight: bold;
	}
}
