.pageHeader {
	$this: &;
	position: relative;
	width: 100%;
	z-index: 999;
	display: flex;
	flex-direction: column;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;

	&__container {
		padding: 0 10px 0 35px;
		z-index: 7;
		@include media($large) {
			padding: 0 95px;
		}
	}
	& + .main {
		margin-top: 26px;
	}
	&__animal {
		top: -56px;
		right: 130px;
		animation-delay: 3s;

		@include media-max($ipad) {
			display: none;
		}
		&:hover {
			top: -27px;
			& + .-text {
				top: -27px;
			}
		}
	}
	~ .subNav {
		@include media-max($menubreak) {
			display: none;
		}
	}
}

.brandContainer {
	&__link {
		display: block;
		border-bottom: none;
		width: fit-content;
		&::before {
			display: none;
		}
	}

	&__image {
		display: block;
		margin: 57px 0;
		transform: translateX(-12.5%);
		width: 200px;
		@include media-max($menubreak) {
			max-width: 100px;
			margin: 17.5px 0 17.5px 0;
		}
	}
}

.headerCta {
	color: $black;
	padding: 20px 60px;
	letter-spacing: 0;
	margin-right: 1.6rem;
	margin-bottom: 1.5rem;
	width: fit-content;
	@include media-max($menubreak) {
		padding: 13px 30px;
		margin-bottom: 0;
		margin-left: 10px;
	}
	&.-disabled {
		pointer-events: none;
		&::before {
			display: none;
		}
	}
	img {
		height: auto;
		width: 110px;
		position: absolute;
		bottom: -20px;
		right: -20px;
		transform: rotate(-12deg);
		box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
		pointer-events: none;
		background-color: $white;
		padding: 5px;
		@include media-max($menubreak) {
			width: 92px;
		}
	}
}

.socialIcons {
	&__link {
		display: inline-block;
		width: 44px;
		height: 44px;
		border-bottom: none !important;
		text-align: center;
		color: $white;
		font-size: 24px;
		vertical-align: top;
		line-height: 44px;
		&::before {
			display: none;
		}
		@include media-max($menubreak) {
			font-size: 18px;
		}
		&:hover,
		&:focus {
			color: $brand-pop;
			opacity: 1;
		}
	}
}

.skip-to-content {
	position: absolute;
	top: 100%;
	left: 0;
	padding: 0 1rem;
	background-color: $black;
	color: $white;
	opacity: 0;
	pointer-events: none;

	@include media-max($ipad) {
		display: none;
	}

	&:focus {
		opacity: 1;
		pointer-events: all;
		color: $white;
	}
}

.topHeader {
	background-color: $brand-dark;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 70px;
	width: 100%;
	z-index: 1000;
	position: relative;

	@include media-max($ipad) {
		display: none;
	}

	&__links {
		display: flex;
		justify-self: flex-end;
		height: 100%;
	}

	&__nav {
		display: inline-flex;
		align-items: center;
		list-style: none;
		margin-right: 1rem;
		&Item {
		}
	}

	&__link.-light {
		z-index: 3;
		padding: 5px 10px;
		border-bottom-color: transparent;
		font-size: 16px;
		line-height: 24px;
		&:hover {
			border-bottom-color: transparent;
		}
	}

	&__exhibitor {
		&Login {
			background-color: rgba($color: $white, $alpha: 0.2);
			height: 100%;
			display: inline-flex;
			align-items: center;
			border: none;

			&::before {
				display: none;
			}

			&::after {
				display: none;
			}

			&:hover {
				background-color: rgba($color: $brand-pop, $alpha: 1);
				p {
					color: $brand-dark;
				}
				svg {
					fill: $brand-dark;
				}
			}

			svg {
				fill: $brand-light;
				width: 21px;
				height: auto;
				margin-left: 14px;
				margin-right: 10px;
				transition: all 250ms ease-in-out;
			}
		}
		&Text {
			font-family: $base-font-family;
			margin: 0;
			font-weight: bold;
			font-size: 12px;
			line-height: 15px;
			width: min-content;
			margin-right: 18px;
			color: $brand-light;
			transition: all 250ms ease-in-out;
		}
	}
}

.headerEvents {
	z-index: 6;
	display: flex;
	width: 100%;
	justify-content: center;
	transition: all 250ms ease-in-out;
	background-color: $brand-dark;
	@include media-max($ipad) {
		flex-direction: column;
	}
	&__item {
		display: flex;
		flex-direction: column;
		max-width: 25%;
		width: 25%;
		@include media-max($ipad) {
			max-width: 100%;
			width: 100%;
		}
		@include media($ipad) {
			&.-isSection {
				border: 2px solid $brand-light;
				border-bottom: none;
				margin-bottom: -2px;
			}
		}
		@include media-max($ipad) {
			border-top: 2px solid $brand-light;
		}
		&:nth-child(1) {
			background-color: $brand-red;
			.subNav {
				background: #f5c0b1;
			}
		}
		&:nth-child(2) {
			background-color: $brand-blue;
			.subNav {
				background: #c7ddeb;
			}
		}
		&:nth-child(3) {
			background-color: $brand-pink;
			.subNav {
				background: #e0cfdb;
			}
		}
		&:nth-child(4) {
			background-color: $brand-orange;
			.subNav {
				background: #f9d3a7;
			}
		}
		.subNav {
			width: 100%;
			overflow: auto;
			border-top: none;
			border-bottom: 2px solid $brand-light;
			flex-direction: column;
			&__link {
				color: $brand-dark;
				white-space: nowrap;
				&::before {
					display: none;
				}
				&:hover {
					border-color: $brand-dark;
				}
				&:focus {
					border: 2px dotted $brand-dark;
					color: $brand-dark;
				}
				&.-isCurrent,
				&.-isSection {
					color: $brand-dark;
					background-color: $white;
					border-color: $brand-dark;
				}
			}
			@include media($menubreak) {
				display: none;
			}
		}
	}
	&__tickets {
		position: absolute;
		right: 50%;
		top: 0;
		transform: translate(50%, -70%);
		@include media-max($ipad) {
			top: 50%;
			transform: translateY(-50%);
			right: 5px;
		}
		@include media-max($small) {
			height: 40px;
			width: auto;
			top: unset;
			transform: unset;
			right: 0;
			bottom: 0;
		}
	}

	&__link {
		padding: 12px 16px 10px;
		text-align: center;
		color: $brand-dark;

		width: 100%;
		@include media-max($ipad) {
			width: 100%;
			max-width: unset;
			padding: 0px 6px 3px;
		}
		border-bottom: none;
		position: relative;
		border: 2px solid transparent;

		&::before {
			display: none;
		}
	}
	&__title {
		font-family: $base-font-family;
		font-size: 34px;
		line-height: 39px;
		font-weight: 900;
		margin: 0;
		text-transform: uppercase;
		display: inline-flex;
		align-items: center;
		@include media-max($ipad) {
			font-size: 18px;
			line-height: 1;
		}
		.fa-angle-down {
			margin-left: 5px;
			transition: all 250ms ease-in-out;
			@include media-max($ipad) {
				font-size: 16px;
			}
			@include media($menubreak) {
				display: none;
			}
			&.-rotated {
				transform: rotate(180deg);
			}
		}
	}

	&__date {
		margin: 0;
		font-size: 14px;
		line-height: 18px;
		@include media-max($ipad) {
			margin-top: -5px;
		}
	}
	svg {
		position: absolute;
		top: calc(100% - 1px);
		left: 0;
		right: 0;
		width: 100%;
		stroke: $brand-dark;
		display: none;
	}
}
