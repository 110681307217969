// =============================================================================
// Lazyloaded images
// =============================================================================

@mixin image-sizing {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.lazy {
	@include image-sizing;

	a & {
		transition: opacity 0.2s;

		&:hover {
			opacity: 0.7;
		}
	}

	&--img {
		display: block; // We need to go full width with this technique
		position: relative;
		height: 0; // Has the padding-bottom ratio technique
		overflow: hidden; // Just in case

		img {
			@include image-sizing;
		}
	}

	&--background {
		display: block;
		position: relative;
		height: 100%;
		overflow: hidden;

		.lazyload,
		.lazyloaded {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: 50%;
		}

		.lazyload,
		.lazyloaded,
		img {
			@include image-sizing;
		}
	}
}

.lazy__placeholder {
	@include image-sizing;
	@include image-sizing;
	max-width: none;
	transition: opacity 1s ease;
	background-color: $grey;
	//will-change: opacity;

	.lazyload + & {
		opacity: 1;
	}

	.lazyloaded + & {
		opacity: 0;
		//transform: scale(1);
	}

	// Background images
	.lazy--background & {
		height: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50%;
	}
}
