/*  ==========================================================================
	# Colors
	======================================================================= */

// Basic
$white: #fff;
$black: #000;

// Project colours
$brand-light: #f9f4ea;
$brand-dark: #2d3c4b;
$brand-pop: #a8d4b3;

$brand-darkpink: $brand-light;

$brand-blue: #95c7ed;
$brand-green: $brand-pop;
$brand-red: #f18c79;
$brand-grey: #e2e4e5;
$brand-orange: #f8b364;
$brand-yellow: #ffd35c;
$brand-pink: #c7abcc;

$brand-text: $brand-dark;
$brand-text-alt: #7a8972;

// Greys
$gray: $brand-grey;
$grey: $gray;

$mid-gray: #555;
$mid-grey: $mid-gray;

$dark-gray: #222;
$dark-grey: $dark-gray;

//Red
$red: #ec6543;

// UI
$error: #ec6543;
$warning: #ec6543;

// Define colour map
$colors: (
	white: $white,
	black: $black,
	grey: $grey,
	mid-grey: $mid-grey,
	dark-grey: $dark-grey,
	brand-light: $brand-light,
	brand-dark: $brand-dark,
	brand-pop: $brand-pop,
	red: $red,
	hotpink: hotpink,
);
