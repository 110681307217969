/*  ==========================================================================
	## NOTIFICATIONS BAR
	======================================================================= */
.notificationBar {
	$b: #{&};

	position: relative;
	background-color: $brand-yellow;

	&__icon {
		@extend %fs-h5;
		position: relative;
		top: 2px;
	}

	&__message {
		max-width: 800px;
		margin: 0 auto;
		padding: 1rem 0;
		text-align: center;
		color: $black;
		line-height: $reduced-line-height;

		@include media($medium) {
			padding: 1.5rem 0 2rem;
		}
	}

	&__link {
		color: $black;
		font-weight: bold;
		text-decoration: underline;
		border-bottom: none;

		&:hover,
		&:focus {
			color: $brand-dark;
		}
	}
}
