/*  ==========================================================================
	# BANNER
	======================================================================= */

.panel__banner {
	position: relative;
	overflow: hidden;

	&.-bg-image {
		z-index: 11;
	}
}

.block__banner {
	position: relative;
	height: 100%;
	padding: 0;
	&.-hasDarkOverlay {
		&::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: rgba($color: $black, $alpha: 0.6);
			z-index: 3;
		}
	}
}

.banner {
	z-index: 1;
	position: relative;
	height: 100%;

	&.-content {
		display: flex;
		flex-direction: column;
		justify-content: center;

		.-small & {
			padding: 4vw 0;
		}

		.-large & {
			padding: 7vw 0;
		}
	}

	.-bg-image &.-content {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 4;
	}

	&.-image {
		.lazy--background {
			min-height: 400px;
		}
	}

	&__contentWrapper {
		position: relative;
		z-index: 1;
		width: 100%;
	}

	&__content {
		position: relative;
		text-align: center;

		@include media-max($ipad) {
			padding: 1rem;
		}
	}

	&__title {
		@extend %fs-hero;
		@include heading(none, $pop-font-family);
		color: $brand-light;
	}
	.-bg-white &__title {
		color: $brand-text;
	}

	&__subtitle {
		@extend %fs-summary;
		margin-top: 1rem;
		color: $black;

		@include media-max($ipad) {
			margin-bottom: 0;
		}
	}

	&__button {
		z-index: 1;
		text-transform: uppercase;
		font-weight: bold;
		left: 50%;
		transform: translateX(-50%);
		width: fit-content;
		display: flex;
		margin-top: 2rem;
		justify-content: center;
		align-items: center;
		text-align: center;
		color: $brand-dark;
		border: 2px solid $brand-dark;
		transition: all 250ms ease-in-out;
		font-size: 31px;

		&::before {
		}

		&:hover {
			background-color: $brand-light;
			color: $brand-dark;
			border-color: $brand-dark;
			background-size: 0;
		}
	}
}

.graphicOnlyBanner {
	width: 100%;
	height: auto;
}
