.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	flex-wrap: wrap;
	margin: 1.5rem 0;
	padding: 2rem 0;
	border-top: solid 1px $black;
	font-size: 18px;
	font-weight: bold;

	@include media($medium) {
		flex-direction: row;
		justify-content: space-between;
	}

	&__text {
		flex: 0 0 auto;
		font-weight: normal;
	}

	&__list {
		display: flex;
		flex: 0 1 auto;
		flex-wrap: wrap;
	}
}

.pagination__button {
	display: inline-flex;
	width: auto;
	margin: 0.5rem;
	padding: 1rem;
	text-align: center;
	align-items: center;
	justify-content: center;
	min-width: 3rem;

	&.-isLink {
		color: $black;
		border-color: $grey;
		background-color: $grey;
	}

	&.-isPrev,
	&.-isNext {
		color: $white;
		border-color: $black;
		background-color: $black;
		padding-top: 0.3rem;
		padding-bottom: 0.3rem;
	}

	&:hover,
	&:active,
	&:focus {
		color: $black;
		border-color: $grey;
		background-color: $white;
	}

	&.-isActive {
		color: $white;
		border-color: $brand-dark;
		background-color: $brand-dark;
	}

	&Icon {
		font-size: 32px;
		font-family: 'Font Awesome 5 Pro';
	}
}
