/* ----- Responsive videos ----- */
.embed-container,
.video-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	margin: 0 0 $small-spacing;
	font-size: 16px;

	iframe,
	object,
	embed,
	.google-map {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

/* ----- No / Only ----- */
.-noPrint {
	@media print {
		display: none !important;
	}
}

.-onlyPrint {
	@media screen {
		display: none !important;
	}
}

.-noWrap {
	white-space: nowrap;
}

.-isHidden {
	display: none !important;
}

/* ----- Accessibility ----- */
/**
* Visually hide an element, but leave it available for screen readers
* @link https://github.com/h5bp/html5-boilerplate/blob/master/dist/css/main.css
* @link http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
*/
.screen-reader {
	position: absolute;
	width: 1px;
	height: 1px;
	overflow: hidden;
	margin: -1px;
	padding: 0;
	border: 0;
	clip: rect(0 0 0 0);
}

/**
* Extends the .screen-reader class to allow the element to be focusable when navigated to via the keyboard
* @link https://github.com/h5bp/html5-boilerplate/blob/master/dist/css/main.css
* @link https://www.drupal.org/node/897638
*/
.screen-reader-focusable:active,
.screen-reader-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	overflow: visible;
	margin: 0;
	clip: auto;
}

.-sticky-top {
	position: sticky;
	top: 0;
}

@keyframes shake {
	0% {
		transform: translate(0, 0) rotate(0, 0);
	}
	90% {
		transform: translate(1px, 1px) rotate(0deg);
	}
	91% {
		transform: translate(-1px, -2px) rotate(-1deg);
	}
	92% {
		transform: translate(-3px, 0px) rotate(1deg);
	}
	93% {
		transform: translate(3px, 2px) rotate(0deg);
	}
	94% {
		transform: translate(1px, -1px) rotate(1deg);
	}
	95% {
		transform: translate(-1px, 2px) rotate(-1deg);
	}
	96% {
		transform: translate(-3px, 1px) rotate(0deg);
	}
	97% {
		transform: translate(3px, 1px) rotate(-1deg);
	}
	98% {
		transform: translate(-1px, -1px) rotate(1deg);
	}
	99% {
		transform: translate(1px, 2px) rotate(0deg);
	}
	100% {
		transform: translate(1px, -2px) rotate(-1deg);
	}
}

.-no-y-padding {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}
