.panel__summary {
	position: relative;

	.summary {
		padding-top: 0.5rem;
		color: $brand-dark;

		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-top: 0;
		}
		p {
			@extend %fs-summary;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&__button {
			margin-top: 2rem;
		}
	}
}
