.modal {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($brand-dark, 0.9);
	z-index: 9999;
	opacity: 0;
	visibility: hidden;
	transform: scale(1.1);
	transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
	&__content {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: $brand-dark;
		padding: 46px;
		max-width: 95%;
		width: 50rem;
		max-height: 95%;
		overflow-y: auto;
		color: $brand-light;
		&.-no-scroll {
			overflow: visible;
			padding: unset !important;
		}
		@include media-max($ipad) {
			max-height: 85%;
			top: 45%;
		}
		@include media-max($small) {
			padding: 56px 10px 20px;
		}
	}
	&__innerScroll {
		overflow: auto;
		overflow-x: clip;
		height: 100%;
		width: 100%;
		padding: 46px;
		@include media-max($small) {
			padding: 36px 10px 60px;
		}
	}
	&__imageContainer {
		position: relative;
		height: 0;
		padding-bottom: 66.66666%;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			border: 2px solid $brand-light;
			z-index: 2;
		}
		.modal__image {
			background-color: $brand-light;
			transform: rotate(-2.5deg);
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
			object-fit: contain;
			&.-cover {
				object-fit: cover;
			}
		}
	}

	&__freeImageContainer {
		position: relative;
	}

	&__freeImage {
		position: relative;
		background-color: $white;
		width: 100%;
		height: auto;
	}

	&__title {
		font-weight: bold;
		padding: 5px 0;
		margin-top: -10px;
		margin-bottom: 34px;
		font-family: $heading-font-family;
		text-align: center;
		font-size: 37px;
		line-height: 45px;
		&.-left {
			text-align: left;
			padding-right: 60px;
			margin-bottom: 1rem;
		}
	}
	&__callout {
		top: 0;
		right: 0;
		transform: translate(10px, -50%) rotate(15deg);
	}

	&__description {
		font-size: 16px;
		line-height: 24px;
		margin: 40px 0 20px;
	}

	&__socials {
		display: flex;
		justify-content: center;
		margin-top: 46px;
		.socialIcons__link {
			font-size: 34px;
			width: 60px;
		}
	}

	&__close {
		position: absolute;
		top: 0;
		right: 0;
		text-align: center;
		cursor: pointer;
		width: 80px;
		height: 80px;
		font-family: $heading-font-family;
		font-weight: bold;
		font-size: 32px;
		line-height: 0.8;
		background-color: $brand-light;
		color: $brand-dark;
		padding: 0.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all 250ms ease-in-out;
		opacity: 63%;
		z-index: 2;
		&::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			border: 2px solid $brand-dark;
			transform: rotate(-5deg);
			z-index: 2;
		}
		&:hover {
			background-color: darkgray;
		}
		@include media-max($small) {
			opacity: 1;
		}
	}
	&__trigger {
		cursor: pointer;
	}
	&__logoContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 2rem;
		img {
			max-height: 100px;
			max-width: 80%;
			height: auto;
			width: auto;
			object-fit: contain;
		}
	}
	&__backBtn {
		position: absolute;
		left: 50%;
		top: 100%;
		transform: translate(-50%, -50%);
		width: fit-content;
		white-space: nowrap;
		&:hover {
			background-color: $brand-light;
		}
	}
}
.show-modal {
	opacity: 1;
	visibility: visible;
	transform: scale(1);
	transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}
