@media print {
	* {
		-webkit-print-color-adjust: exact;
		color: #000 !important;
		box-shadow: none !important;
		text-shadow: none !important;
	}

	@page {
		margin: 2cm 0.5cm;
	}

	/*  ==========================================================================
	## Set font to 16px/12pt, set background to white and font to black.
	======================================================================= */

	body {
		font-family: $base-font-family;
		font-size: 12pt;
		line-height: 1.3;
		background: #fff !important;
		color: #000;
	}

	h1 {
		font-size: 24pt;
	}

	h2,
	h3,
	h4 {
		font-size: 14pt;
		margin-top: 25px;
	}

	/*  ==========================================================================
	## Defining all page breaks
	======================================================================= */

	blockquote {
		page-break-inside: avoid;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		page-break-after: avoid;
		page-break-inside: avoid;
	}

	img {
		page-break-inside: avoid;
		page-break-after: avoid;
	}

	table,
	pre {
		page-break-inside: avoid;
	}

	ul,
	ol,
	dl {
		page-break-before: avoid;
	}

	/*  ==========================================================================
	## Displaying link color and link behaviour
	======================================================================= */

	a:link,
	a:visited,
	a {
		background: transparent;
		color: #00843d;
	}

	a {
		page-break-inside: avoid;
	}

	/*  ==========================================================================
	## Hiding Videos and Other iframes.
	======================================================================= */

	iframe {
		display: none;
		width: 0 !important;
		height: 0 !important;
		overflow: hidden !important;
		line-height: 0pt !important;
		white-space: nowrap;
	}

	.embed-container,
	.video-container {
		position: absolute;
		height: 0;
		overflow: hidden;
	}

	p,
	address,
	li,
	dt,
	dd,
	blockquote {
		font-size: 100%;
	}

	code,
	pre {
		font-family: 'Courier New', Courier, mono;
	}

	ul,
	ol {
		list-style: square;
		margin-left: 18pt;
		margin-bottom: 20pt;
	}

	li {
		line-height: 1.6em;
	}

	/*  ==========================================================================
	## Hiding unnecessary elements
	======================================================================= */

	.-noPrint {
		display: none !important;
	}
}
