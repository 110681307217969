/*  ==========================================================================
	# Cards
	======================================================================= */

.panel__exhibitorCards {
	padding: 4rem 0;
	&.-bg-dark {
		.card__blockHeading .heading {
			color: $brand-light;
		}
		.card {
			&:hover {
				&::after {
					border-color: $brand-light;
				}
			}
		}
	}
}

.exhibitorCards {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 1.5rem -1.5rem;
}

.exhibitorCard {
	@include transition(all);
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;
	height: 100%;
	margin: 0 1.5rem;
	line-height: 1.4;
	background-color: $brand-blue;
	border-bottom: none;
	@include media-max($large) {
		margin: 0 1rem;
	}
	@include media-max($small) {
		margin: 0;
	}

	&:not(a) {
		pointer-events: none;
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border: 3px solid $brand-dark;
		z-index: 2;
		transition: all 250ms ease-in-out;
	}

	&__blockHeading {
		text-align: center;
		margin-bottom: 2rem;
	}

	&__container {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
		width: 25%;
		@include media-max($large) {
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
		@include media-max($ipad) {
			width: 50%;
		}
		@include media-max($small) {
			width: 100%;
		}
	}

	&__image {
		width: 100%;
		padding-bottom: 75%;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		background-color: $brand-light;
		display: block;
		transition: all 250ms ease-in-out;
	}

	&__contentContainer {
		position: relative;
		height: 100%;
		width: 100%;
		padding: 1.5rem;
		background-position: 50% 100%;
		background-size: 0% 6px;
		background-repeat: no-repeat;
		transition: background-color 350ms $transition-timing-function;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__container:nth-child(6n + 1) &__image {
		transform: rotate(-4deg);
	}
	&__container:nth-child(6n + 2) &__image {
		transform: rotate(3deg);
	}
	&__container:nth-child(6n + 3) &__image {
		transform: rotate(4deg);
	}
	&__container:nth-child(6n + 4) &__image {
		transform: rotate(-3deg);
	}
	&__container:nth-child(6n + 5) &__image {
		transform: rotate(2deg);
	}
	&__container:nth-child(6n + 6) &__image {
		transform: rotate(-2deg);
	}

	&__title {
		@extend %fs-h4;
		position: relative;
		width: 100%;
		padding-bottom: 0.5rem;
		margin-bottom: 1rem;
		font-family: $heading-font-family;
		margin: 0;
		text-align: center;
		color: $brand-dark;
	}

	&__link {
		@extend %fs-small;
		display: block;
		padding: 1rem 1rem 0.75rem;
		font-weight: bold;
		flex-grow: 1;
		background-color: $grey;
	}

	&__content + &__link {
		flex-grow: 0;
	}

	&:hover {
		background-color: $brand-dark;
		.exhibitorCard__title {
			color: $brand-light;
		}
		.exhibitorCard__image {
			transform: rotate(0);
		}
	}
}
