.searchPage.panel {
	padding-top: 0;
}

.searchResults {
	&__title {
		margin: 0.5rem 0;
		font-size: 18px;
		font-family: $base-font-family;
	}

	&__url {
		color: #666666;
		font-size: 14px;
		line-height: 18px;
	}

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__item {
		margin-bottom: 0.5rem;
		padding: 1rem 1.5rem;
		background-color: $white;
		display: block;
		border-bottom: none;

		&::before {
			display: none;
		}

		&::after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			border: 2px solid $brand-dark;
			opacity: 0;
			transition: all 250ms ease-in-out;
		}
		&:hover {
			background-color: transparent;
			z-index: 2;
			&::after {
				opacity: 1;
				transform: rotate(-2deg);
			}
		}
		*:last-child {
			margin-bottom: 0;
		}
	}

	&__link {
		margin-top: 1rem;
		display: inline-block;
		border-bottom: none;
	}

	&__summary {
		font-size: 16px;
		line-height: 24px;
	}
	&__heading {
		h3 {
			margin-top: 0;
			font-family: $base-font-family;
			font-size: 26px;
			line-height: 28px;
			font-weight: 400;
		}
	}
}

.searchPage__form {
	margin-bottom: 3rem;
}
